import React from "react";
import styled from "styled-components/macro";
import { Button } from "./Button";

interface ButtonsContainerProps {
  flatButton: {
    textTrKey: string;
    onClick: () => void;
    hideArrow?: boolean;
  };
  justify?: string;
  submitButton: {
    textTrKey: string;
    disabled?: boolean;
  };
}

export const ButtonsContainer: React.FC<ButtonsContainerProps> = (props) => {
  const { flatButton, submitButton, justify } = props;

  return (
    <Wrapper justify={justify}>
      <PrevButton
        onClick={flatButton.onClick}
        buttonType="secondary"
        type="button"
        leftIconName={flatButton.hideArrow ? undefined : "arrow-left"}
        textTrKey={flatButton.textTrKey}
      />
      <ConfirmButton
        type="submit"
        buttonType="primary"
        disabled={submitButton.disabled}
        textTrKey={submitButton.textTrKey}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  justify?: string;
}>`
  display: flex;
  flex-direction: row;
  ${({ justify }) =>
    (justify && `justify-content: ${justify};`) || `justify-content: space-between;`};
`;

const PrevButton = styled(Button)`
  width: auto;
  margin-right: 6px;
  min-width: 214px;

  ${({ theme }) => theme.media.belowSm} {
    min-width: 162px;
  }

  color: ${({ theme }) => theme.colors.primary100};
`;

const ConfirmButton = styled(Button)`
  width: auto;
  min-width: 214px;
  margin-left: 6px;

  ${({ theme }) => theme.media.belowSm} {
    min-width: 162px;
  }
`;
