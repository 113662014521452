import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";

type Condition = {
  labelTrKey: string;
  testFn: (phrase: string) => boolean;
};

interface ValidatorProps {
  title: string;
  conditions: Condition[];
  phrase: string;
  setIsPhraseSufficient: (isSufficent: boolean) => void;
}

export const Validator: React.FC<ValidatorProps> = React.memo((props) => {
  let isPhraseSufficient = true;
  let preparedConditions = props.conditions.map((condition) => {
    const filled = condition.testFn(props.phrase);
    if (!filled) isPhraseSufficient = false;

    return {
      ...condition,
      label: t(condition.labelTrKey),
      filled: filled,
    };
  });

  useEffect(() => {
    props.setIsPhraseSufficient(isPhraseSufficient);
  }, [props, isPhraseSufficient]);

  return (
    <Wrapper>
      <Title>{props.title}</Title>
      {preparedConditions.map((condition) => (
        <StyledCondition filled={condition.filled} key={condition.label}>
          {condition.label}
        </StyledCondition>
      ))}
    </Wrapper>
  );
});

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const Title = styled.span`
  padding-top: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colorsOldFixME.black100};
`;

const StyledCondition = styled.li<{ filled: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 0px;
  color: ${({ theme }) => theme.colorsOldFixME.black100};

  ${({ filled, theme }) =>
    !filled
      ? `
      color: ${theme.colors.error};
      &:before {
        content: "\\e947";
        font-family: "pru-internal_iconfont";
        padding-right: 6px;
        }
  `
      : `
      &:before {
        content: "\\e90f";
        padding-right: 6px;
        color: ${theme.colors.success};
        font-family: "pru-internal_iconfont";
        }
  `}
`;
