import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { t } from "../../intl";
import { Button, Checkbox } from "../../components/UIComponents";

export const Cookies: React.FC = () => {
  return (
    <>
      <Backdrop />
      <Content>
        <Frame>
          <StyledTitle>{t("cookies.title")}</StyledTitle>
          <TextNeutral100>
            <FormattedMessage
              id="cookies.maininfo"
              values={{
                cookiesPolicy: (
                  <Link
                    href={`${process.env.PUBLIC_URL}/files/Polityka_cookies_mojePru.pdf`}
                    download
                  >
                    {t("cookies.cookiesPolicy")}
                  </Link>
                ),
                statute: (
                  <Link
                    href={`${process.env.PUBLIC_URL}/files/Regulamin_korzystania_z_portalu_mojePru.pdf`}
                    download
                  >
                    {t("cookies.statute")}
                  </Link>
                ),
              }}
            />
          </TextNeutral100>
          <SubFrame>
            <SubTitleWrapper>
              <Subtitle>{t("cookies.subtitleBasic")}</Subtitle>
              <StyledCheckbox checked={true} disabled={true} />
            </SubTitleWrapper>
            <TextNeutral100>{t("cookies.contentBasic")}</TextNeutral100>
          </SubFrame>
          <AnnotationWrapper>
            <AnnotationStar>*</AnnotationStar>
            <AnnotationText>{t("cookies.footerText")}</AnnotationText>
          </AnnotationWrapper>
        </Frame>
        <ActionBar>
          <ButtonWrapper>
            <StyledButton
              onClick={() => {
                document.cookie = `cookiesAccepted=true; path=/; max-age=${60 * 60 * 24 * 30}`;
                window.location.href = "/home";
              }}
              textTrKey={"cookies.buttonText"}
            />
          </ButtonWrapper>
        </ActionBar>
      </Content>
    </>
  );
};

const Backdrop = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.gray60};
  opacity: 0.6;
  z-index: 11;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  max-width: 800px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colorsOldFixME.neutral10};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colorsOldFixME.elevation_16},
    0px 0px 1px ${({ theme }) => theme.colorsOldFixME.elevation_32};
  z-index: 12;

  ${({ theme }) => theme.media.belowMd} {
    position: absolute;
    left: 16px;
    right: 16px;
    top: 16px;
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  left: 0px;
  top: 0px;

  ${({ theme }) => theme.media.belowMd} {
    padding: 16px;
  }
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #e3e3e3;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 188px;
  height: 84px;
`;

const StyledButton = styled(Button)`
  width: 157px;
  height: 52px;
  background-color: ${({ theme }) => theme.colors.primary100};
  padding: 16px 32px;
  white-space: nowrap;
`;

const StyledTitle = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primary100};

  ${({ theme }) => theme.media.belowMd} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const TextNeutral100 = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const AnnotationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AnnotationStar = styled(TextNeutral100)`
  color: ${({ theme }) => theme.colors.error};
`;

const AnnotationText = styled(TextNeutral100)`
  margin: 0px 10px;
`;

const Link = styled.a`
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary100};

  &:hover {
    color: ${({ theme }) => theme.colors.gray100};
  }

  &:hover:not(:active) {
    text-decoration: none;
  }
`;

const SubFrame = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  box-sizing: border-box;
  border-radius: 4px;
  margin: 24px 0px;
  padding: 20px;

  ${({ theme }) => theme.media.belowMd} {
    margin: 12px 0px;
  }
`;
const SubTitleWrapper = styled.div`
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 16px 0px;
`;

const Subtitle = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: inline-block;
  position: relative;
  color: ${({ theme }) => theme.colors.grayDark};

  &:after {
    content: "*";
    color: ${({ theme }) => theme.colors.error};
  }

  ${({ theme }) => theme.media.belowMd} {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: auto;
  margin-top: 3px;
`;
