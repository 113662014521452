import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { Button, Title } from "../../components/UIComponents";
import { userActions } from "../../slices/userSlice";
import Success from "../../assets/success.svg";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";

export const Logout: React.FC = () => {
  const { name } = useSelector((state: DefaultRootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (name) dispatch(userActions.logout());
  }, [name, dispatch]);

  return (
    <Wrapper>
      <HeaderImage alt={"success"} image={Success} width={"160px"} height={"160px"} />
      <StyledTitle>{t("common.loggedOut")}</StyledTitle>
      <StyledButton onClick={() => navigate("/")} textTrKey={"common.goToLogin"} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 426px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled(Title)`
  margin: 32px 0;
`;

const StyledButton = styled(Button)`
  width: 246px;
`;
