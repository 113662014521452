import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import Padlock from "../../assets/padlock.svg";
import { userActions } from "../../slices/userSlice";
import { ButtonsContainer, FormWrapper, Title } from "../../components/UIComponents";
import { AuthorizationCodeInfo } from "../../components/AuthorizationCodeInfo/AuthorizationCodeInfo";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { localErrorsMessages } from "../../constants/errors";
import SendSmsAgainButton from "../../components/SendSmsAgainButton/SendSmsAgainButton";
import { ShadowedBox } from "../../components/UIComponents/styled";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

export const RemindLoginSubmit: React.FC = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, errorTrKey, formData } = useSelector(
    (state: DefaultRootState) => state.user
  );
  const [authorizationCode, setAuthorizationCode] = useState("");
  const [isResendSmsLoaderVisible, setIsResendSmsLoaderVisible] = useState(false);

  const navigate = useNavigate();

  const isAuthorizationCodeValid = !(
    errorTrKey === localErrorsMessages["007"] && authorizationCode === formData.authorizationCode
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (authorizationCode && loadingStatus !== loadingStatuses.pending) {
      dispatch(
        userActions.loginRemindConfirm({
          authorizationCode,
          policyNo: formData.policyNo,
        })
      );
    }
  };

  const resendAuthorizationCode = useCallback(() => {
    if (isResendSmsLoaderVisible) return;
    dispatch(userActions.loginRemindInit({ policyNo: formData.policyNo }));
    setIsResendSmsLoaderVisible(true);
    setTimeout(() => setIsResendSmsLoaderVisible(false), 1000);
  }, [isResendSmsLoaderVisible, dispatch, formData]);

  return (
    <FormWrapper onSubmit={onSubmit}>
      <HeaderImage alt={Padlock} image={Padlock} />
      <AuthorizationCodeInfo />
      <ShadowedBox padding={32} showBelowMd={true}>
        <Wrapper>
          <Title>{t("remindUsernameForm.title")}</Title>
          <InputWrapper>
            <InputFormElement
              labelProps={{
                labelTrKey: "common.placeholders.authorizationCode",
              }}
              inputProps={{
                value: authorizationCode,
                onChange: setAuthorizationCode,
                isInvalid: !isAuthorizationCodeValid,
                validationMessagesTrKeys: [{ trKey: errorTrKey }],
                inputType: "password",
              }}
            />
            <SendSmsAgainButton onClick={resendAuthorizationCode} />
          </InputWrapper>
        </Wrapper>
      </ShadowedBox>
      <ButtonsContainer
        flatButton={{
          textTrKey: "button.back",
          onClick: () => navigate(-1),
        }}
        submitButton={{
          textTrKey: "button.next",
          disabled: !authorizationCode || !isAuthorizationCodeValid,
        }}
      />
    </FormWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
