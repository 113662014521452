import React from "react";
import styled from "styled-components/macro";

interface HeaderImageProps {
  image: string;
  alt: string;
  width?: string;
  height?: string;
}

export const HeaderImage: React.FC<HeaderImageProps> = (props) => {
  const { image, alt, width, height } = props;
  return (
    <Container>
      <ImageWrapper width={width || "80px"} height={height || "80px"}>
        <Image src={image} alt={alt} />
      </ImageWrapper>
    </Container>
  );
};

const Image = styled.img`
  display: block;
`;

const ImageWrapper = styled.div<{ width: string; height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.inset1};
  border-radius: 50%;
  margin: 0px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
