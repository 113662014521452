import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { userActions } from "../../slices/userSlice";
import { ButtonsContainer, ErrorMessage, Title } from "../../components/UIComponents";
import { AuthorizationCodeInfo } from "../../components/AuthorizationCodeInfo/AuthorizationCodeInfo";
import { Validator } from "../../components/UIComponents/Validator";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { localErrorsMessages } from "../../constants/errors";
import SendSmsAgainButton from "../../components/SendSmsAgainButton/SendSmsAgainButton";
import { FormWrapperWide, ShadowedBox } from "../../components/UIComponents/styled";
import Phone from "../../assets/phone.svg";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

const newLoginValidationConditions = [
  {
    labelTrKey: "changeUsername.validatorConditions.min5Max50CharsAndAllowedSigns",
    testFn: (phrase: string) =>
      phrase.length >= 5 && phrase.length <= 50 && !/[^a-zA-Z@.\-_0-9]/.test(phrase),
  },
  {
    labelTrKey: "changeUsername.validatorConditions.minOneLetter",
    testFn: (phrase: string) => /[a-zA-Z]/.test(phrase),
  },
];

export const ChangeLogin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, errorTrKey, formData } = useSelector(
    (state: DefaultRootState) => state.user
  );
  const [authorizationCode, setAuthorizationCode] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [confirmedNewUsername, setConfirmedNewUsername] = useState("");
  const [isUsernamesMatch, setIsUsernamesMatch] = useState(true);
  const [isUsernameSufficient, setIsUsernameSufficient] = useState(false);
  const [isResendSmsLoaderVisible, setIsResendSmsLoaderVisible] = useState(false);

  const navigate = useNavigate();

  const isAuthorizationCodeValid = !(
    errorTrKey === localErrorsMessages["007"] && authorizationCode === formData.authorizationCode
  );

  const isNewUsernameValid = !(
    errorTrKey === localErrorsMessages["012"] && newUsername === formData?.newUsername
  );

  const isFormValid = [
    !!authorizationCode,
    !!confirmedNewUsername,
    isUsernameSufficient,
    isUsernamesMatch,
    isAuthorizationCodeValid,
    isNewUsernameValid,
  ].every((i) => i === true);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (newUsername !== confirmedNewUsername) {
      setIsUsernamesMatch(false);
      return;
    }

    if (isFormValid && loadingStatus !== loadingStatuses.pending) {
      dispatch(
        userActions.loginChangeConfirm({
          authorizationCode,
          newUsername,
          username: formData.username,
        })
      );
    }
  };

  const resendAuthorizationCode = useCallback(() => {
    if (isResendSmsLoaderVisible) return;
    dispatch(userActions.loginChangeInit({ username: formData.username }));
    setIsResendSmsLoaderVisible(true);
    setTimeout(() => setIsResendSmsLoaderVisible(false), 1000);
  }, [isResendSmsLoaderVisible, formData.username, dispatch]);

  return (
    <FormWrapperWide onSubmit={onSubmit}>
      <Wrapper>
        <HeaderWrapper>
          <HeaderImage alt={"phone"} image={Phone} />
          <Title>{t("changeUsername.title")}</Title>
          <AuthorizationCodeInfo textCenter={true} />
        </HeaderWrapper>
        <ContainerBox width={823} padding={32}>
          <ItemBox>
            <ItemTitle>{t("registerForm.enterOneTimepassword")}</ItemTitle>
            <InputFormElement
              labelProps={{
                labelTrKey: "common.placeholders.authorizationCode",
              }}
              inputProps={{
                value: authorizationCode,
                onChange: setAuthorizationCode,
                isInvalid: !isAuthorizationCodeValid,
                validationMessagesTrKeys: [{ trKey: errorTrKey }],
                inputType: "password",
              }}
            />
            {!!isAuthorizationCodeValid && <SendSmsAgainButton onClick={resendAuthorizationCode} />}
          </ItemBox>

          <ItemBox>
            <ItemTitle>{t("changeUsername.setNewLogin")}</ItemTitle>
            <InputFormElement
              labelProps={{
                labelTrKey: "changeUsername.newUsernamePlaceholder",
              }}
              inputProps={{
                value: newUsername,
                onChange: (value) => {
                  setNewUsername(value);
                  !isUsernamesMatch && setIsUsernamesMatch(true);
                },
                isInvalid: !isUsernamesMatch || !isNewUsernameValid,
              }}
            />

            <InputFormElement
              labelProps={{
                labelTrKey: "changeUsername.repeatNewUsernamePlaceholder",
              }}
              inputProps={{
                value: confirmedNewUsername,
                onChange: (value) => {
                  setConfirmedNewUsername(value);
                  !isUsernamesMatch && setIsUsernamesMatch(true);
                },
                isInvalid: !isUsernamesMatch || !isNewUsernameValid,
              }}
            />

            {!isUsernamesMatch && (
              <StyledErrorMessage>{t("changeUsername.usernamesNotMatch")}</StyledErrorMessage>
            )}

            {!isNewUsernameValid && errorTrKey && (
              <StyledErrorMessage>{t(errorTrKey)}</StyledErrorMessage>
            )}

            <Validator
              conditions={newLoginValidationConditions}
              phrase={newUsername}
              setIsPhraseSufficient={setIsUsernameSufficient}
              title={t("changeUsername.usernameConditionsTitle")}
            />
          </ItemBox>
        </ContainerBox>
        <ButtonsContainer
          justify={`center`}
          flatButton={{
            textTrKey: "button.back",
            onClick: () => navigate(-1),
          }}
          submitButton={{
            textTrKey: "button.next",
            disabled: !isFormValid,
          }}
        />
      </Wrapper>
    </FormWrapperWide>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-bottom: 17px;
  margin-top: 0;
`;

const ItemTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 24px;
`;

const ContainerBox = styled(ShadowedBox)`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => theme.media.belowMd} {
    flex-direction: column;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.belowMd} {
    margin-bottom: 32px;

    &:first-child {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -19px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.gray30};
      }
    }
  }

  ${({ theme }) => theme.media.aboveMd} {
    &:first-child {
      padding-right: 64px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        right: 32px;
        background-color: ${({ theme }) => theme.colors.gray30};
      }

      &:before {
        content: "";
        height: 12px;
        width: 12px;
        border-top: 1px solid ${({ theme }) => theme.colors.gray30};
        border-right: 1px solid ${({ theme }) => theme.colors.gray30};
        transform: rotate(45deg);
        position: absolute;
        right: 26px;
        top: 50%;
        z-index: 2;
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
