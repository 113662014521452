import React, { FC } from "react";
import styled from "styled-components/macro";
import { t, tExists } from "../../intl";

const footerLinks = ["statute", "cookiesPolicy", "privacyPolicy"];

interface FooterElement {
  title: string;
  url: string;
}

const footerUrls = footerLinks.reduce((footerTab: FooterElement[], linkName) => {
  const { title, link, filename } = {
    title: `footer.${linkName}.title`,
    link: `footer.${linkName}.link`,
    filename: `footer.${linkName}.filename`,
  };
  const linkExists = tExists(link);
  const footerElement = {
    title: t(title),
    url: linkExists ? t(link) : `${process.env.PUBLIC_URL}/files/${t(filename)}`,
  } as FooterElement;
  footerTab.push(footerElement);
  return footerTab;
}, []) as FooterElement[];

export const Footer: FC = () => {
  return (
    <Wrapper>
      <LinksContainer>
        {footerUrls.map((fElement) => (
          <LinkWrapper key={fElement.title}>
            <a href={fElement.url} download target="_blank" rel="noopener noreferrer">
              {fElement.title}
            </a>
          </LinkWrapper>
        ))}
      </LinksContainer>
      <Copyright>{t("footer.copyright")}</Copyright>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray100};
  line-height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  box-sizing: border-box;

  ${({ theme }) => theme.media.belowXl} {
    left: 0;
  }

  ${({ theme }) => theme.media.belowMd} {
    font-size: 12px;
    line-height: 16px;
  }
`;

const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${({ theme }) => theme.media.belowMd} {
    grid-template-columns: repeat(2, 1fr);

    & > :first-child {
      grid-column: 1 / 3;
      border-top: 0;
      border-bottom: 0;
    }
  }
`;

const Container = styled.div`
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.belowMd} {
    height: 32px;
  }
`;

const LinkWrapper = styled(Container)`
  border: 1px solid ${({ theme }) => theme.colors.gray30};

  &:first-child {
    justify-content: flex-start;
    padding-left: 48px;
  }

  &:last-child {
    justify-content: flex-end;
    padding-right: 48px;
  }

  &:first-child,
  &:last-child {
    border-left: 0;
    border-right: 0;
  }

  ${({ theme }) => theme.media.belowLg} {
    &:first-child,
    &:last-child {
      justify-content: center;
      padding: 0;
    }
  }
`;

const Copyright = styled(Container)`
  padding: 24px 40px;

  ${({ theme }) => theme.media.belowMd} {
    padding: 12px 40px;
  }
`;
