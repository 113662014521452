import { Input, InputProps } from "./Input";
import React from "react";
import styled from "styled-components/macro";
import { Label, LabelProps } from "./Label";

export interface InputFormProps {
  inputProps: Omit<InputProps, "disabled">;
  labelProps?: Omit<LabelProps, "disabled">;
  disabled?: boolean;
}

export const InputFormElement = ({ inputProps, labelProps, disabled }: InputFormProps) => {
  return (
    <Wrapper>
      {labelProps && <Label {...labelProps} disabled={disabled} />}
      <Input {...inputProps} disabled={disabled} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;
