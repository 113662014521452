import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { appReducer } from "./slices/appSlice";
import { modalsReducer } from "./slices/modalsSlice";
import { userReducer } from "./slices/userSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export type AppThunk<R = void> = ThunkAction<R, DefaultRootState, null, Action<string>>;

export type ThunkPromise<T = void> = Promise<T> & { abort(): void };

const rootReducer = combineReducers({
  app: appReducer,
  modals: modalsReducer,
  user: userReducer,
});

export type DefaultRootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type AppDispatch = ThunkDispatch<DefaultRootState, any, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<DefaultRootState> = useSelector;
