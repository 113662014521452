import styled, { css } from "styled-components/macro";
import { Button } from "./Button";

export const Title = styled.div`
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.belowMd} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const FormWrapper = styled.form`
  max-width: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormWrapperWide = styled.form`
  max-width: 823px;

  ${({ theme }) => theme.media.belowMd} {
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const SubmitButton = styled(Button)`
  margin-top: 10px;
`;

export const ShowBelowXl = styled.div`
  display: none;

  @media (max-width: 1199px) {
    display: block;
  }
`;

export const HideBelowXl = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
`;

export const ShowBelowLg = styled.div`
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
`;

export const HideBelowLg = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`;

export const ShowBelowMd = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const HideBelowMd = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ShadowedBox = styled.div<{
  width?: number;
  padding?: number;
  showBelowMd?: boolean;
}>`
  max-width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};

  ${({ width }) => (width && `width: ${width}px;`) || `width: auto;`}
  ${({ padding }) => (padding && `padding: ${padding}px;`) || `padding: 48px;`}
  ${({ theme }) => theme.media.belowMd} {
    padding: 16px;

    ${({ showBelowMd }) =>
      !showBelowMd &&
      css`
        border: none;
        box-shadow: none;
        background: none;
      `}
  }
`;
