import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { userActions } from "../../slices/userSlice";
import { Button, FormWrapper, Title } from "../../components/UIComponents";
import { loadingStatuses } from "../../constants/loadingStatuses";
import styled from "styled-components";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";
import { ShadowedBox } from "../../components/UIComponents/styled";

export const RemindLoginInit: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, formData } = useSelector((state: DefaultRootState) => state.user);
  const [policyNo, setPolicyNo] = useState("");

  useEffect(() => {
    if (formData) setPolicyNo(formData.policyNo);
  }, [formData]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (policyNo && loadingStatus !== loadingStatuses.pending) {
      dispatch(userActions.loginRemindInit({ policyNo }));
    }
  };

  return (
    <ShadowedBox width={440}>
      <FormWrapper onSubmit={onSubmit}>
        <Title>{t("remindUsernameForm.title")}</Title>
        <InputWrapper>
          <InputFormElement
            labelProps={{
              labelTrKey: "common.placeholders.policyNo",
            }}
            inputProps={{
              value: policyNo,
              onChange: setPolicyNo,
            }}
          />
          <Button
            disabled={!policyNo || loadingStatus === loadingStatuses.pending}
            type="submit"
            textTrKey="common.sendAuthorizationCode"
          />
        </InputWrapper>
      </FormWrapper>
    </ShadowedBox>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
