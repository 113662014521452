import React, { FC } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DefaultRootState, useAppDispatch } from "../../store";
import { modalsActions } from "../../slices/modalsSlice";
import { HideBelowXl, Icon } from "../UIComponents";
import logo from "../../assets/logo.svg";
import { headerHeight, mobileHeaderHeight } from "../../constants/layout";
import headerIcon from "../../assets/padlock.svg";
import { userActions } from "../../slices/userSlice";

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const { name } = useSelector((state: DefaultRootState) => state.user);
  const location = useLocation();

  const showLogoutModal = () => {
    dispatch(
      modalsActions.show({
        headerTrKey: "common.modal.logoutHeader",
        messageTrKey: "common.modal.logoutText",
        pictureSrc: headerIcon,
        buttons: {
          cancel: {
            textTrKey: "button.back",
          },
          confirm: {
            textTrKey: "common.modal.logout",
            onClick: () => dispatch(userActions.logout()),
          },
        },
      })
    );
  };

  const showAuthorizedItems = name && location.pathname !== "/logout";

  return (
    <Wrapper>
      <HeaderWrapper>
        <Flex1 />
        <Logo src={logo} />
        <User>
          {showAuthorizedItems && (
            <>
              <HideBelowXl>
                <UserName>{name}</UserName>
              </HideBelowXl>
              <LogoutIcon name="log-out" onClick={showLogoutModal} />
            </>
          )}
        </User>
      </HeaderWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 42px;
  height: ${headerHeight}px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1;
  position: relative;

  ${({ theme }) => theme.media.belowMd} {
    height: ${mobileHeaderHeight}px;
    padding: 10px 15px;
  }
`;

const Flex1 = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  ${({ theme }) => theme.media.belowMd} {
    order: initial;
    width: 83px;
  }
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const UserName = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 0.91;
  margin-right: 20px;
  text-align: right;
`;

const LogoutIcon = styled(Icon)`
  width: 45px;
  min-width: 45px;
  height: 45px;
  background: ${({ theme }) => theme.colors.gray10};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-left: 4px;

  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }

  ${({ theme }) => theme.media.belowMd} {
    width: 36px;
    min-width: 36px;
    height: 36px;
    font-size: 16px;
  }
`;
