import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { Modal, ModalOptions } from "../models/Modal";

const initialState: { items: Modal[] } = {
  items: [],
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    show: (state, action: PayloadAction<ModalOptions>) => {
      state.items.push({ ...action.payload, uuid: uuidv4() });
    },
    close: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter((item) => item.uuid !== action.payload);
    },
  },
});

export const modalsReducer = modalsSlice.reducer;
export const modalsActions = modalsSlice.actions;
