import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import { DefaultThemeColor } from "../../theme/defaultTheme";
import { Icon } from "./Icon";

interface TooltipProps {
  text: string;
  iconWidth?: number;
  iconColor?: DefaultThemeColor;
  className?: string;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const { text, iconWidth, iconColor, className } = props;
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [wrapperOffsetLeft, setWrapperOffsetLeft] = useState(0);
  const onWrapperClick = (wrapperEl: HTMLDivElement) => {
    if (!isMessageVisible) setWrapperOffsetLeft(wrapperEl.getBoundingClientRect().left);
    setTimeout(() => setIsMessageVisible(!isMessageVisible));
  };

  return (
    <Wrapper
      onClick={(e: React.MouseEvent) => {
        const targetEl = e.target as HTMLDivElement;
        if (targetEl.id !== "msg") onWrapperClick(targetEl);
      }}
      onBlur={() => setIsMessageVisible(false)}
      className={className}
      tabIndex={-1}
    >
      <Icon
        name="info"
        size={iconWidth || 18}
        color={iconColor || "primary100"}
        hoverColor={"gray100"}
      />
      {isMessageVisible && (
        <>
          <Message width={280} id="msg" wrapperOffsetLeft={wrapperOffsetLeft}>
            {text}
          </Message>
          <Arrow />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  outline: 0;
  pointer-events: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0px;

  > * {
    pointer-events: auto;
  }
`;

const Message = styled.div<{
  width: number;
  wrapperOffsetLeft: number;
}>`
  cursor: auto;
  position: absolute;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 100vw;
  background-color: ${({ theme }) => theme.colors.gray100};
  z-index: 1;
  white-space: pre-wrap;

  ${({ theme }) => theme.media.aboveMd} {
    width: ${({ width }) => width}px;
    bottom: 22px;
    left: calc(50% - ${({ width }) => width / 2}px);
  }

  ${({ theme }) => theme.media.belowMd} {
    margin-top: 20px;
    left: ${({ wrapperOffsetLeft }) => -wrapperOffsetLeft + 45}px;
    width: calc(100vw - 90px);
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.belowMd} {
    display: none;
  }
`;
