import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { userActions } from "../../slices/userSlice";
import { ButtonsContainer, FormWrapper, Title } from "../../components/UIComponents";
import { ShadowedBox } from "../../components/UIComponents/styled";
import Success from "../../assets/success.svg";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

export const ConfirmLogin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formData, name } = useSelector((state: DefaultRootState) => state.user);
  useEffect(() => {
    if (!name) dispatch(userActions.getUserInfo());
  }, [name, dispatch]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(userActions.loginChangeInit({ username: formData.username }));
  };

  return (
    <FormWrapper onSubmit={onSubmit}>
      <HeaderWrapper>
        <HeaderImage alt={"success"} image={Success} />
        <Title>{t("confirmUsername.youAreLogged")}&nbsp;</Title>
        <SubTitle>
          {t("confirmUsername.yourUsernameIs")}
          <span>{formData.username}.</span>
        </SubTitle>
        <InfoText>{t("confirmUsername.changeUsernameInfo")}</InfoText>
      </HeaderWrapper>
      <ShadowedBox padding={32} showBelowMd={true}>
        <InputFormElement
          disabled={true}
          labelProps={{
            labelTrKey: "common.login",
            tooltipTrKey: "common.usernameTooltipText",
          }}
          inputProps={{
            value: formData.username,
            onChange: undefined,
            hideValidationInfoWhenValid: true,
          }}
        />
      </ShadowedBox>
      <ButtonsContainer
        flatButton={{
          textTrKey: "confirmUsername.skip",
          onClick: () => (window.location.href = "/cp"),
          hideArrow: true,
        }}
        submitButton={{
          textTrKey: "confirmUsername.changeUsername",
        }}
      />
    </FormWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Text = styled.span`
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
`;

const SubTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.secondary100};
  }
`;

const InfoText = styled(Text)`
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;
