import React, { useCallback, useEffect, useRef, useState, FC } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const channelName = "cpOneTab";
const responseTimeout = 100;

enum OneTabStatus {
  Starting = "Starting",
  FirstTab = "FirstTab",
  SecondTab = "SecondTab",
}

const tabUuid = uuidv4();

export const OneTab: FC = (props) => {
  const [status, setStatus] = useState(OneTabStatus.Starting);

  const statusRef = useRef<OneTabStatus>(status);
  statusRef.current = status;

  const sendMessageToChannel = (message: string) => {
    const msg = JSON.stringify({
      message,
      tabUuid,
    });
    window.localStorage.setItem(channelName, msg);
    window.localStorage.removeItem(channelName);
  };

  const isResponseFromOtherTab = (e: StorageEvent) =>
    e.newValue !== null && JSON.parse(e.newValue).tabUuid !== tabUuid;

  const handleOtherTabResponse = useCallback(
    (e: StorageEvent) => {
      if (e.key === channelName && isResponseFromOtherTab(e)) {
        if (statusRef.current === OneTabStatus.Starting) {
          setStatus(OneTabStatus.SecondTab);
        }
        if (statusRef.current === OneTabStatus.FirstTab) {
          sendMessageToChannel("YES");
        }
      }
    },
    [statusRef]
  );

  const handleWaitingForResponseTimeout = useCallback(() => {
    if (statusRef.current === OneTabStatus.Starting) {
      setStatus(OneTabStatus.FirstTab);
    }
  }, [statusRef]);

  useEffect(() => {
    window.addEventListener("storage", handleOtherTabResponse);
    sendMessageToChannel("AYT?");
    const timer = setTimeout(handleWaitingForResponseTimeout, responseTimeout);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("storage", handleOtherTabResponse);
    };
  }, [handleOtherTabResponse, handleWaitingForResponseTimeout]);

  return (
    <>
      {status === OneTabStatus.FirstTab && props.children}
      {status === OneTabStatus.SecondTab && (
        <SecondTabBackground>
          <SecondTabMessage>
            <FormattedMessage id="login.error.oneTabOnly" />
          </SecondTabMessage>
        </SecondTabBackground>
      )}
    </>
  );
};

const SecondTabBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 11;
  padding: 20px;
`;

const SecondTabMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  text-align: center;
  margin: 300px auto 0;
  width: 652px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsOldFixME.black100};

  ${({ theme }) => theme.media.belowMd} {
    margin: 200px auto 0;
    width: 100%;
  }
`;
