import React from "react";
import styled, { css } from "styled-components/macro";
import { Icon, IconWeight } from "./Icon";

export interface LinkButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: React.ReactNode;
  hasDarkBackground?: boolean;
  leftIconName?: string;
  rightIconName?: string;
  size?: number;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  onClick,
  leftIconName,
  rightIconName,
  size,
  ...props
}) => (
  <Wrapper>
    {leftIconName && (
      <StyledIcon
        size={size || 16}
        name={leftIconName}
        hasDarkBackground={props.hasDarkBackground}
        disabled={props.disabled}
        weight={IconWeight.bold}
      />
    )}
    <StyledLinkButton size={size} onClick={props.disabled ? undefined : onClick} {...props} />
    {rightIconName && (
      <StyledIcon
        size={size || 16}
        name={rightIconName}
        hasDarkBackground={props.hasDarkBackground}
        disabled={props.disabled}
        weight={IconWeight.bold}
      />
    )}
  </Wrapper>
);

const Wrapper = styled.span`
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
`;

const StyledIcon = styled(Icon)<{ disabled?: boolean; hasDarkBackground?: boolean }>`
  ${({ theme, disabled, hasDarkBackground }) => {
    const primaryColor = theme.colors[hasDarkBackground ? "white" : "primary100"];
    const color = disabled ? theme.colors.primaryDisabled : primaryColor;
    return css`
      color: ${color};
    `;
  }}
`;

const StyledLinkButton = styled.span<LinkButtonProps>`
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  font-size: ${({ size }) => size || 16}px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  ${({ theme, disabled, hasDarkBackground }) => {
    const primaryColor = theme.colors[hasDarkBackground ? "white" : "primary100"];
    const color = disabled ? theme.colors.primaryDisabled : primaryColor;

    return css`
      color: ${color};
    `;
  }}
  &:hover {
    ${({ theme, disabled, hasDarkBackground }) =>
      !disabled &&
      css`
        color: ${hasDarkBackground ? theme.colors.white : theme.colors.gray100};
      `}
  }

  &:hover:not(:active) {
    ${({ disabled }) => (disabled ? "" : `text-decoration: none;`)}
  }
`;
