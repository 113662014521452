import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { userActions } from "../../slices/userSlice";
import { Button, FormWrapper, Title } from "../../components/UIComponents";
import { loadingStatuses } from "../../constants/loadingStatuses";
import styled from "styled-components";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

export const ResetPasswordInit: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, formData } = useSelector((state: DefaultRootState) => state.user);
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (formData) setUsername(formData.username);
  }, [formData]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (username && loadingStatus !== loadingStatuses.pending) {
      dispatch(userActions.passwordResetInit({ username }));
    }
  };

  return (
    <Container>
      <FormWrapper onSubmit={onSubmit}>
        <Title>{t("resetPasswordForm.title")}</Title>
        <InputWrapper>
          <InputFormElement
            labelProps={{
              labelTrKey: "common.login",
              tooltipTrKey: "common.usernameTooltipText",
            }}
            inputProps={{
              value: username,
              onChange: setUsername,
            }}
          />
          <Button
            disabled={!username || loadingStatus === loadingStatuses.pending}
            type="submit"
            textTrKey="common.sendAuthorizationCode"
          />
        </InputWrapper>
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 56px;
  max-width: 440px;

  ${({ theme }) => theme.media.belowMd} {
    width: 328px;
    margin-top: 0px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
