import { createGlobalStyle } from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colorsOldFixME.black100};
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;    
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.6px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

    * {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colorsOldFixME.black100};
    }
  }
`;
