export interface ModalError {
  key: string;
  logout?: boolean;
}

export const modalErrors = {
  "014": {
    key: "apiErrors.accountExist",
  },
  "015": {
    key: "apiErrors.changeUsername30DayLimit",
  },
  "105": {
    key: "apiErrors.accountExpired",
  },
  "106": {
    key: "apiErrors.accountLocked",
    logout: true,
  },
  "108": {
    key: "apiErrors.accountDisabled",
  },
  "016": {
    key: "apiErrors.blockedAuthorizationCode",
  },
};

export const localErrorsMessages = {
  "007": "apiErrors.wrongAuthorizationCode",
  "010": "apiErrors.wrongAuthorizationCode",
  "012": "apiErrors.existingUsername",
  "013": "apiErrors.previouslyUsedPassword",
  "101": "apiErrors.wrongUsernameOrPassword",
};

export const landingErrors = ["000", "003", "005", "006", "009", "011", "100"];
