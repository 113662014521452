export const plMessages = {
  common: {
    sendAuthorizationCode: "Wyślij SMS z hasłem jednorazowym",
    sendAuthorizationCodeAgain: "Ponownie wyślij SMS z hasłem jednorazowym",
    insertPassword: "Podaj kod zabezpieczający",
    usernameTooltipText:
      "Login generowany jest przez system na etapie rejestracji.\nUżytkownik ma możliwość zmiany swojego loginu w dowolnym momencie z poziomu zakładki “Moje Dane”.",
    authorizationCodeInfo:
      "Wysłaliśmy Ci hasło jednorazowe na telefon.\nJeśli nie otrzymałeś hasła sprawdź wprowadzone dane lub skontaktuj się z {authorizationCodeModalButton}.",
    authorizationCodeModalText:
      "Skontaktuj się infolinią pod numerem (+48) 801 30 20 10\nlub (+48) 22 667 58 21 (pn-pt 9:00-18:00).",
    authorizationCodeModalButton: "infolinią",
    modal: {
      logoutHeader: "Zakończenie sesji",
      logoutText: "Czy chcesz się wylogować z mojePru?",
      logout: "Wyloguj",
    },
    placeholders: {
      username: "login",
      policyNo: "Numer wniosku lub polisy",
      authorizationCode: "Hasło jednorazowe",
    },
    loggedOut: "Pomyślnie wylogowano z serwisu mojePru",
    goToLogin: "Przejdź do logowania",
    login: "Login",
    password: "Hasło",
  },
  button: {
    back: "Wstecz",
    next: "Dalej",
    cancel: "Anuluj",
    ok: "OK",
  },
  menu: {
    myPolicies: "Moje Polisy",
    myData: "Moje Dane",
    contact: "Kontakt",
  },
  apiErrors: {
    wrongUsernameOrPassword: "Nieprawidłowy login lub hasło.",
    accountExpired:
      "Skontaktuj się z infolinią pod numerem\n(+48) 801 30 20 10 lub (+48) 22 667 58 21 (pn-pt 9:00-18:00).",
    accountLocked:
      "Konto zostało zablokowane. Skontaktuj się z infolinią pod numerem\n(+48) 801 30 20 10 lub (+48) 22 667 58 21 (pn-pt 9:00-18:00).",
    accountDisabled:
      "Skontaktuj się z infolinią pod numerem\n(+48) 801 30 20 10 lub (+48) 22 667 58 21 (pn-pt 9:00-18:00).",
    changeUsername30DayLimit:
      "Ze względów bezpieczeństwa, zmiana loginu możliwa jest\ntylko 3 razy na 30 dni.",
    accountExist:
      "Konto o podanych danych już istnieje.\nProsimy o zalogowanie się do mojePru korzystając z loginu i hasła.",
    previouslyUsedPassword: "Spróbuj ponownie, wpisując nieużywane wcześniej hasło.",
    existingUsername: "Podany login nie może zostać wykorzystany. Prosimy o wybór innego loginu.",
    wrongAuthorizationCode: "Nieprawidłowe hasło.",
    blockedAuthorizationCode:
      "Hasło jednorazowe zostało zablokowane. W celu dokończenia rejestracji konieczne jest wygenerowanie nowego SMS z hasłem jednorazowym.",
  },
  usernameForm: {
    title: "Logowanie",
    passwordPlaceholder: "hasło",
    submit: "Zaloguj",
    enterPasswordToUsername: "Wprowadź hasło, żeby się zalogować.",
    links: {
      forgotPassword: "Zapomniałem hasło",
      forgotUsername: "Zapomniałem login",
      register: "Rejestracja",
    },
  },
  registerForm: {
    pidPlaceholder: "Identyfikator",
    title: "Rejestracja",
    enterOneTimepassword: "Wprowadź hasło jednorazowe",
    setNewPassword: "Ustaw nowe hasło",
    pidTooltipText:
      "Identyfikatorem jest:\n- PESEL (dla klientów indywidualnych),\n- Numer dokumentu tożsamości (jeśli nie masz nadanego PESEL),\n- NIP bez myślników (jeśli występujesz w imieniu firmy).",
  },
  changePasswordForm: {
    title: "Zmiana hasła",
  },
  remindUsernameForm: {
    title: "Przypomnienie loginu",
  },
  resetPasswordForm: {
    title: "Resetowanie hasła",
  },
  passwordForm: {
    newPasswordPlaceholder: "Nowe hasło",
    repeatNewPasswordPlaceholder: "Powtórz nowe hasło",
    passwordConditionsTitle: "Hasło powinno zawierać:",
    passwordsNotMatch: "Nowe hasło oraz powtórzone nowe hasło nie są takie same.",
    validatorConditions: {
      min12Max50Char: "minimum 12, maksimum 50 znaków",
      minOneUppercase: "co najmniej jedną wielką literę",
      minOneDigit: "co najmniej jedną cyfrę",
      minOneSpecial: "co najmniej jeden znak specjalny (np. !#%{*})",
    },
  },
  confirmUsername: {
    changeUsername: "Zmień login",
    skip: "Pomiń",
    youAreLogged: "Jesteś zalogowany.",
    yourUsernameIs: "Twój login to: ",
    changeUsernameInfo:
      "Dla swojej wygody możesz teraz zmienić login na taki, który będzie Ci łatwiej zapamiętać.",
  },
  changeUsername: {
    title: "Zmiana loginu",
    newUsernamePlaceholder: "Nowy login",
    usernamesNotMatch: "Nowy login oraz powtórzony nowy login nie są takie same",
    repeatNewUsernamePlaceholder: "Powtórz nowy login",
    usernameConditionsTitle: "Login powinien zawierać:",
    setNewLogin: "Ustaw nowy login",
    validatorConditions: {
      min5Max50CharsAndAllowedSigns:
        "minimum 5, maksimum 50 znaków (możliwe do użycia znaki specjalne @ . - _)",
      minOneLetter: "co najmniej jedną wielką lub małą literę alfabetu łacińskiego",
    },
  },
  mfa: {
    authorizationCodeInfo:
      "Nie rozpoznaliśmy tego urządzenia dlatego wysłaliśmy\nCi hasło jednorazowe na telefon.\nJeśli nie otrzymałeś hasła sprawdź wprowadzone dane lub skontaktuj się z {authorizationCodeModalButton}.",
    title: "Dodaj urządzenie do zaufanych",
    isTrusted:
      "Nie będziemy Cię prosić o hasło jednorazowe przez następne 30 dni podczas logowania z tego urządzenia.",
    submit: "Zaloguj",
  },
  cookies: {
    title: "Strona wykorzystuje pliki cookies",
    maininfo:
      "Aby móc korzystać ze Strony wymagany jest wybór preferencji korzystania z ciasteczek. Jednocześnie informujemy, że możesz w dowolnym momencie zmienić ustawienia dotyczące plików cookies w swojej przeglądarce. Szczegółowe informacje znajdują się na stronie dot. {cookiesPolicy} oraz w {statute}.",
    cookiesPolicy: "polityki cookies",
    statute: "Regulaminie",
    buttonText: "Zgadzam się",
    subtitleBasic: "Podstawowe",
    contentBasic:
      "Używamy własnych plików cookies w celu sprawdzenia, czy Twoja przeglądarka obsługuje pliki cookies oraz w celu zapewnienia Ci wygody przeglądania Strony, w tym ograniczenie emisji pojawiających się powiadomień (np. powiadomień o używaniu plików cookies). Używamy w tym celu zarówno cookies sesyjnych, jak i trwałych, przy czym cykl życia plików trwałych nie przekracza 30 dni.",
    footerText: "ciasteczka niezbędne do poprawnego działania strony",
  },
  "footer.statute.title": "Regulamin korzystania z mojePru",
  "footer.statute.filename": "Regulamin_korzystania_z_portalu_mojePru.pdf",
  "footer.cookiesPolicy.title": "Polityka Cookies",
  "footer.cookiesPolicy.filename": "Polityka_cookies_mojePru.pdf",
  "footer.privacyPolicy.title": "Polityka prywatności",
  "footer.privacyPolicy.link": "https://www.pru.pl/polityka-prywatnosci",
  "footer.copyright": "Copyright © 2022 Pru.pl",
  "login.error.oneTabOnly":
    "Nie można otworzyć mojePru na więcej niż jednej zakładce tej samej przeglądarki.",
};
