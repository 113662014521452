import React, { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { DefaultRootState, useAppDispatch } from "../../store";
import { Button } from "../UIComponents";
import { t } from "../../intl";
import { modalsActions } from "../../slices/modalsSlice";

export const Modal: FC = () => {
  const dispatch = useAppDispatch();
  const { items } = useSelector((state: DefaultRootState) => state.modals);

  if (!items.length) return <div />;

  return (
    <Mask>
      {items.map((modal) => {
        const { pictureSrc, headerTrKey, messageTrKey } = modal;
        const { cancel, confirm } = modal.buttons;

        return (
          <ModalWrapper key={modal.uuid}>
            <ContentWrapper>
              <Content>
                {pictureSrc && (
                  <PictureWrapper>
                    <Picture>
                      <img width={88} height={88} src={pictureSrc} />
                    </Picture>
                  </PictureWrapper>
                )}
                {headerTrKey && <TextHeader>{t(headerTrKey)}</TextHeader>}
                {messageTrKey && <Message>{t(messageTrKey)}</Message>}
              </Content>
            </ContentWrapper>
            <HorizontalLine />
            <ButtonsWrapper>
              {cancel && (
                <Button
                  buttonType={"secondary"}
                  onClick={() => {
                    dispatch(modalsActions.close(modal.uuid));
                    // @ts-ignore
                    cancel?.onClick();
                  }}
                  textTrKey={cancel.textTrKey}
                  leftIconName={"arrow-left"}
                />
              )}
              {confirm && (
                <Button
                  buttonType={"primary"}
                  onClick={() => {
                    dispatch(modalsActions.close(modal.uuid));
                    // @ts-ignore
                    confirm?.onClick();
                  }}
                  textTrKey={confirm.textTrKey}
                />
              )}
            </ButtonsWrapper>
          </ModalWrapper>
        );
      })}
    </Mask>
  );
};

const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.gray100_07};
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const ModalWrapper = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 652)}px;
  min-width: 300px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  overflow: auto;
  padding: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: 120px;
  width: 120px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows.inset1};
`;

const Picture = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
`;

const TextHeader = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primary100};
  text-align: center;
  padding: 0px 24px;
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
  text-align: center;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
`;
