import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { ButtonsContainer, ErrorMessage, Title } from "../../components/UIComponents";
import { AuthorizationCodeInfo } from "../../components/AuthorizationCodeInfo/AuthorizationCodeInfo";
import { Validator } from "../../components/UIComponents/Validator";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { localErrorsMessages } from "../../constants/errors";
import { CommonAsyncThunkAction, passwordFormModes } from "./modes";
import { modesData, newPasswordValidationConditions } from "./consts";
import SendSmsAgainButton from "../../components/SendSmsAgainButton/SendSmsAgainButton";
import { FormWrapperWide, ShadowedBox } from "../../components/UIComponents/styled";
import Phone from "../../assets/phone.svg";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

export const PasswordForm: React.FC<{ mode: passwordFormModes }> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, errorTrKey, formData } = useSelector(
    (state: DefaultRootState) => state.user
  );
  const [authorizationCode, setAuthorizationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
  const [isPasswordSufficient, setIsPasswordSufficient] = useState(false);
  const [isResendSmsLoaderVisible, setIsResendSmsLoaderVisible] = useState(false);

  const navigate = useNavigate();

  const selectedMode = modesData[props.mode];

  const isAuthorizationCodeValid = !(
    errorTrKey === localErrorsMessages["007"] && authorizationCode === formData.authorizationCode
  );

  const isNewPasswordValid = !(
    errorTrKey === localErrorsMessages["013"] && newPassword === formData?.newPassword
  );

  const isFormValid = [
    !!authorizationCode,
    !!confirmedNewPassword,
    isPasswordSufficient,
    isPasswordsMatch,
    isAuthorizationCodeValid,
    isNewPasswordValid,
  ].every((i) => i === true);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmedNewPassword) {
      setIsPasswordsMatch(false);
      return;
    }

    if (isFormValid && loadingStatus !== loadingStatuses.pending) {
      let parameters: any = {};

      if (props.mode === passwordFormModes.set) {
        parameters = {
          authorizationCode,
          password: newPassword,
          pid: formData.pid,
          policyNo: formData.policyNo,
        };
      } else {
        parameters = {
          authorizationCode,
          newPassword,
          username: formData.username,
        };
      }

      dispatch(selectedMode.dispatchAction(parameters) as unknown as CommonAsyncThunkAction);
    }
  };

  const resendAuthorizationCode = useCallback(() => {
    if (isResendSmsLoaderVisible) return;
    dispatch(
      selectedMode.sendAuthMessageAgainAction(formData) as unknown as CommonAsyncThunkAction
    );
    setIsResendSmsLoaderVisible(true);
    setTimeout(() => setIsResendSmsLoaderVisible(false), 1000);
  }, [isResendSmsLoaderVisible, dispatch, selectedMode, formData]);

  return (
    <FormWrapperWide onSubmit={onSubmit}>
      <Wrapper>
        <HeaderWrapper>
          <HeaderImage alt={"phone"} image={Phone} />
          <Title>{t(modesData[props.mode].titleTrKey)}</Title>
          <AuthorizationCodeInfo textCenter={true} />
        </HeaderWrapper>
        <ContainerBox width={823} padding={32}>
          <ItemBox>
            <ItemTitle>{t("registerForm.enterOneTimepassword")}</ItemTitle>
            <InputFormElement
              labelProps={{
                labelTrKey: "common.placeholders.authorizationCode",
              }}
              inputProps={{
                value: authorizationCode,
                onChange: setAuthorizationCode,
                isInvalid: !isAuthorizationCodeValid,
                inputType: "password",
                validationMessagesTrKeys: [{ trKey: errorTrKey }],
              }}
            />
            {!!isAuthorizationCodeValid && <SendSmsAgainButton onClick={resendAuthorizationCode} />}
          </ItemBox>
          <ItemBox>
            <ItemTitle>{t("registerForm.setNewPassword")}</ItemTitle>
            <InputFormElement
              labelProps={{
                labelTrKey: "passwordForm.newPasswordPlaceholder",
              }}
              inputProps={{
                value: newPassword,
                onChange: (value) => {
                  setNewPassword(value);
                  !isPasswordsMatch && setIsPasswordsMatch(true);
                },
                isInvalid: !isPasswordsMatch || !isNewPasswordValid,
                inputType: "password",
              }}
            />

            <InputFormElement
              labelProps={{
                labelTrKey: "passwordForm.repeatNewPasswordPlaceholder",
              }}
              inputProps={{
                value: confirmedNewPassword,
                onChange: (value) => {
                  setConfirmedNewPassword(value);
                  !isPasswordsMatch && setIsPasswordsMatch(true);
                },
                isInvalid: !isPasswordsMatch || !isNewPasswordValid,
                inputType: "password",
              }}
            />

            {!isPasswordsMatch && (
              <StyledErrorMessage>{t("passwordForm.passwordsNotMatch")}</StyledErrorMessage>
            )}

            {!isNewPasswordValid && errorTrKey && (
              <StyledErrorMessage>{t(errorTrKey)}</StyledErrorMessage>
            )}

            <Validator
              conditions={newPasswordValidationConditions}
              phrase={newPassword}
              setIsPhraseSufficient={setIsPasswordSufficient}
              title={t("passwordForm.passwordConditionsTitle")}
            />
          </ItemBox>
        </ContainerBox>
        <ButtonsContainer
          justify={`center`}
          flatButton={{
            textTrKey: "button.back",
            onClick: () => navigate(-1),
          }}
          submitButton={{
            textTrKey: "button.next",
            disabled: !isFormValid,
          }}
        />
      </Wrapper>
    </FormWrapperWide>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 24px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-bottom: 17px;
  margin-top: 0;
`;

const ContainerBox = styled(ShadowedBox)`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => theme.media.belowMd} {
    flex-direction: column;
    width: 100%;
    background-color: #fff;
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.belowMd} {
    margin-bottom: 32px;

    &:first-child {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -19px;
        height: 1px;
        background-color: #e3e3e3;
      }
    }
  }

  ${({ theme }) => theme.media.aboveMd} {
    &:first-child {
      padding-right: 64px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        right: 32px;
        background-color: #e3e3e3;
      }

      &:before {
        content: "";
        height: 12px;
        width: 12px;
        border-top: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
        transform: rotate(45deg);
        position: absolute;
        right: 26px;
        top: 50%;
        z-index: 2;
        background-color: #fff;
      }
    }
  }
`;
