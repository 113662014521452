import React, { useCallback, useState } from "react";
import { browserName, osName } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { AuthorizationCodeInfo } from "../../components/AuthorizationCodeInfo/AuthorizationCodeInfo";
import { ButtonsContainer, Checkbox, FormWrapper } from "../../components/UIComponents";
import { localErrorsMessages } from "../../constants/errors";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { t } from "../../intl";
import { userActions } from "../../slices/userSlice";
import { AppDispatch, DefaultRootState } from "../../store";
import SendSmsAgainButton from "../../components/SendSmsAgainButton/SendSmsAgainButton";
import { ShadowedBox } from "../../components/UIComponents/styled";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";
import Padlock from "../../assets/padlock.svg";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

export const MfaForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, errorTrKey, formData } = useSelector(
    (state: DefaultRootState) => state.user
  );
  const [authorizationCode, setAuthorizationCode] = useState("");
  const [trustedDevice, setTrustedDevice] = useState(true);
  const [resendSmsLoaderVisible, setResendSmsLoaderVisible] = useState(false);

  const isAuthorizationCodeValid = !(
    errorTrKey === localErrorsMessages["007"] && authorizationCode === formData.authorizationCode
  );

  const isFormValid = [!!authorizationCode, isAuthorizationCodeValid].every((i) => i);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid && loadingStatus !== loadingStatuses.pending) {
      dispatch(
        userActions.activateSessionConfirm({
          authorizationCode,
          deviceName: `${osName} ${browserName}`,
          trustDevice: trustedDevice ? "Y" : "N",
        })
      );
    }
  };

  const resendAuthorizationCode = useCallback(() => {
    if (resendSmsLoaderVisible) return;
    dispatch(userActions.activateSessionInit());
    setResendSmsLoaderVisible(true);
    setTimeout(() => setResendSmsLoaderVisible(false), 1000);
  }, [resendSmsLoaderVisible, dispatch]);

  return (
    <FormWrapper onSubmit={onSubmit}>
      <HeaderImage alt={Padlock} image={Padlock} />
      <AuthorizationCodeInfo i18nHeaderId="mfa.authorizationCodeInfo" />
      <ShadowedBox padding={32} showBelowMd={true}>
        <Wrapper>
          <SubTitle>{t("common.insertPassword")}</SubTitle>
          <InputFormElementWrapper>
            <InputFormElement
              labelProps={{
                labelTrKey: "common.placeholders.authorizationCode",
              }}
              inputProps={{
                value: authorizationCode,
                onChange: setAuthorizationCode,
                isInvalid: !isAuthorizationCodeValid,
                inputType: "password",
                validationMessagesTrKeys: [{ trKey: errorTrKey }],
              }}
            />
            {!!isAuthorizationCodeValid && <SendSmsAgainButton onClick={resendAuthorizationCode} />}
          </InputFormElementWrapper>
          <Separator />
          <AddDeviceToTrusted>
            <ContainerAddDevice>
              <ContainerAddDeviceTitle>{t("mfa.title")}</ContainerAddDeviceTitle>
              <StyledCheckbox checked={trustedDevice} onChange={setTrustedDevice} />
            </ContainerAddDevice>
            <TextSmall>{t("mfa.isTrusted")}</TextSmall>
          </AddDeviceToTrusted>
        </Wrapper>
      </ShadowedBox>

      <ButtonsContainer
        flatButton={{
          textTrKey: "button.back",
          onClick: () => dispatch(userActions.dropActivateSession()),
        }}
        submitButton={{
          textTrKey: "mfa.submit",
          disabled: !isFormValid,
        }}
      />
    </FormWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const AddDeviceToTrusted = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContainerAddDevice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerAddDeviceTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: auto;
  margin-top: 3px;
  display: inline-flex;
  text-align: right;
  align-self: center;
`;

const TextSmall = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray80};
`;

const SubTitle = styled.div`
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const InputFormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
`;
