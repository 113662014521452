import { createSlice } from "@reduxjs/toolkit";

interface IAppSlice {
  loadingCount: number;
}

const initialState: IAppSlice = {
  loadingCount: 0,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    incrementLoadingCount: (state) => {
      state.loadingCount++;
    },
    decrementLoadingCount: (state) => {
      state.loadingCount--;
    },
  },
});

export const appReducer = appSlice.reducer;
export const appActions = { ...appSlice.actions };
