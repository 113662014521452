import { BrowserHistory, createBrowserHistory } from "history";
import { NavigateFunction, To } from "react-router-dom";

interface CustomHistory extends BrowserHistory {
  navigate?: NavigateFunction;
}

export const history: CustomHistory = {
  ...createBrowserHistory({ window }),
  navigate: undefined
};

export const pushToHistory = (to: To, state?: any) => {
  if (!history.navigate) return;

  history.navigate(to, { state });
}

export const replaceHistory = (to: To, state?: any) => {
  if (!history.navigate) return;

  history.navigate(to, { replace: true, state });
}
