export const headerHeight = 80;
export const mobileHeaderHeight = 64;

export const appPaddingTop = 48;
export const appPaddingX = 20;
export const appPaddingBottom = 100;

export const mobileAppPaddingTop = 40;
export const mobileAppPaddingX = 16;
export const mobileAppPaddingBottom = 128;
