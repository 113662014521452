import React, { FC } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "styled-components/macro";
import { Normalize } from "styled-normalize";
import { RawIntlProvider } from "react-intl";
import { defaultTheme } from "./theme/defaultTheme";
import { GlobalStyle } from "./theme/GlobalStyle";
import { App } from "./App";
import { intl } from "./intl";
import { BrowserRouter } from "react-router-dom";
import NavigateSetter from "./NavigateSetter";

export const Providers: FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <RawIntlProvider value={intl}>
        <div>
          <Normalize />
          <BrowserRouter basename={`/${process.env.REACT_APP_PREFIX}`}>
            <NavigateSetter />
            <App />
          </BrowserRouter>
        </div>
      </RawIntlProvider>
    </ThemeProvider>
  </Provider>
);
