import { AppDispatch, store } from "../store";
import { history, pushToHistory } from "../history";
import { modalsActions } from "../slices/modalsSlice";
import { userActions } from "../slices/userSlice";
import { Services } from "./Services";
import { localErrorsMessages, ModalError, modalErrors } from "../constants/errors";
import { Store } from "@reduxjs/toolkit";
import { appActions } from "../slices/appSlice";

interface ApiRequest {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  body?: any;
  headers?: Headers;
}

export class ApiService {
  private apiPrefix = "/home/api";
  private errorUrl = "/service";

  private modalErrors = Object.keys(modalErrors);
  private localErrors = Object.keys(localErrorsMessages);

  private appStore?: Store;
  private dispatch?: AppDispatch;

  constructor() {
    import("../store").then((importedStore) => {
      this.appStore = importedStore.store;
      this.dispatch = this.appStore.dispatch;
    });
  }

  protected get = (url: string) =>
    this.doFetch({
      url: `${this.apiPrefix}/${url}`,
      method: "GET",
    });

  protected post = (url: string, body: any, headers?: Headers) =>
    this.doFetch({
      url: `${this.apiPrefix}/${url}`,
      method: "POST",
      body: body && JSON.stringify(body),
      headers: headers,
    });

  protected put = (url: string, body: any) =>
    this.doFetch({
      url: `${this.apiPrefix}/${url}`,
      method: "PUT",
      body,
    });

  protected delete = (url: string, body?: any) =>
    this.doFetch({
      url: `${this.apiPrefix}/${url}`,
      method: "DELETE",
      body,
    });

  private showErrorModal = async (errorCode: keyof typeof modalErrors) => {
    const modalError: ModalError = modalErrors[errorCode];

    if (modalError.logout) {
      const appState = this.appStore?.getState();
      const { name } = appState?.user || {};
      if (name) {
        await Services.User.logout();
      }
    }

    if (modalError.key === "apiErrors.blockedAuthorizationCode") {
      this.dispatch!(
        modalsActions.show({
          messageTrKey: modalError.key,
          buttons: {
            confirm: {
              textTrKey: "common.sendAuthorizationCode",
              onClick: () => this.dispatch!(userActions.registerInit(undefined)),
            },
          },
        })
      );
    } else {
      this.dispatch!(
        modalsActions.show({
          messageTrKey: modalError.key,
          buttons: {
            confirm: {
              textTrKey: "button.ok",
              onClick: () => {
                if (modalError.logout) {
                  window.location.href = "/home";
                } else {
                  pushToHistory("/");
                  this.dispatch!(userActions.dumpState);
                }
              },
            },
          },
        })
      );
    }
  };

  private doFetch = async (apiRequest: ApiRequest) => {
    try {
      store.dispatch(appActions.incrementLoadingCount());
      const result = await fetch(apiRequest.url, {
        method: apiRequest.method,
        body: apiRequest.body,
        headers: apiRequest.headers || new Headers({ "Content-Type": "application/json" }),
        credentials: "same-origin",
      });
      if (!result.ok) throw result;
      return result;
    } catch (error: any) {
      let parsedError = undefined;
      try {
        parsedError = await error.json().catch(() => {});
      } catch {
        try {
          await Services.User.logout();
        } finally {
          window.location.href = this.errorUrl;
        }
        return await Promise.reject(error);
      }

      if (parsedError) {
        if (this.modalErrors.includes(parsedError.errorCode)) {
          this.showErrorModal(parsedError.errorCode);
          return await Promise.reject(error);
        }

        if (this.localErrors.includes(parsedError.errorCode)) {
          return await Promise.reject(
            localErrorsMessages[parsedError.errorCode as keyof typeof localErrorsMessages]
          );
        }
      }

      if (error.status === 401) {
        if (!history.location.pathname.includes("/logout")) pushToHistory("/");
        this.dispatch!(userActions.dumpState);
        return await Promise.reject(error);
      }

      if (error.status === 409) {
        if (!history.location.pathname.includes("/mfa")) pushToHistory("/mfa");
        return await Promise.reject(error);
      }

      try {
        await Services.User.logout();
      } finally {
        window.location.href = this.errorUrl;
      }
      return await Promise.reject(error);
    } finally {
      setTimeout(() => {
        store.dispatch(appActions.decrementLoadingCount());
      }, 100);
    }
  };
}
