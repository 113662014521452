import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { AppDispatch } from "../../store";
import { modalsActions } from "../../slices/modalsSlice";
import { LinkButton } from "../UIComponents/LinkButton";

interface AuthorizationCodeInfoProps {
  i18nHeaderId?: string;
  textCenter?: boolean;
}

export const AuthorizationCodeInfo: FC<AuthorizationCodeInfoProps> = ({
  i18nHeaderId = "common.authorizationCodeInfo",
  textCenter,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const showModal = () =>
    dispatch(
      modalsActions.show({
        messageTrKey: "common.authorizationCodeModalText",
        buttons: {
          confirm: { textTrKey: "button.ok" },
        },
      })
    );

  return (
    <Wrapper textCenter={textCenter}>
      <FormattedMessage
        id={i18nHeaderId}
        values={{
          authorizationCodeModalButton: (
            <LinkButton size={16} onClick={() => showModal()}>
              {t("common.authorizationCodeModalButton")}
            </LinkButton>
          ),
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ textCenter?: boolean }>`
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 16px;
  ${({ textCenter }) => (textCenter ? `text-align: center` : ``)};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.belowMd} {
    white-space: initial;
  }
`;
