import React, { FC } from "react";
import styled from "styled-components/macro";
import { Routes } from "./Routes";
import { Header } from "./components/Header/Header";
import { Loader, Modal } from "./components/UIComponents";
import { useAppSelector } from "./store";
import {
  appPaddingBottom,
  appPaddingTop,
  appPaddingX,
  mobileAppPaddingBottom,
  mobileAppPaddingTop,
  mobileAppPaddingX,
  headerHeight,
  mobileHeaderHeight,
} from "./constants/layout";
import { Footer } from "./components/Footer/Footer";

export const App: FC = () => {
  const loadingCount = useAppSelector((state) => state.app.loadingCount);
  return (
    <>
      <div className="app-wrapper">
        {!!loadingCount && <Loader />}
        <Wrapper>
          <Header />
          <FooterPaddingWrapper>
            <Routes />
          </FooterPaddingWrapper>
          <Footer />
        </Wrapper>
      </div>
      <Modal />
    </>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray10};
  min-height: 100vh;
  padding: ${appPaddingTop + headerHeight}px ${appPaddingX}px ${appPaddingBottom}px;
  display: flex;
  justify-content: center;
  position: relative;

  ${({ theme }) => theme.media.belowMd} {
    padding: ${mobileAppPaddingTop + mobileHeaderHeight}px ${mobileAppPaddingX}px
      ${mobileAppPaddingBottom}px;
  }
`;

const FooterPaddingWrapper = styled.div`
  padding-bottom: 15px;
  ${({ theme }) => theme.media.belowMd} {
    padding-bottom: 0;
  }
`;
