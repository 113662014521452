import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { userActions } from "../../slices/userSlice";
import { FormWrapper, SubmitButton, Title } from "../../components/UIComponents";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { ShadowedBox } from "../../components/UIComponents/styled";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";

export const RegisterForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, formData } = useSelector((state: DefaultRootState) => state.user);
  const [policyNo, setPolicyNo] = useState("");
  const [pid, setPid] = useState("");

  useEffect(() => {
    if (formData) {
      setPolicyNo(formData.policyNo);
      setPid(formData.pid);
    }
  }, [formData]);

  const isFormValid = !!(policyNo && pid);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid && loadingStatus !== loadingStatuses.pending) {
      dispatch(userActions.registerInit({ policyNo, pid: pid.toUpperCase() }));
    }
  };

  return (
    <div>
      <ShadowedBox width={440} padding={32}>
        <FormWrapper onSubmit={onSubmit}>
          <Title>{t("registerForm.title")}</Title>
          <InputFormElement
            labelProps={{
              labelTrKey: "common.placeholders.policyNo",
            }}
            inputProps={{
              value: policyNo,
              onChange: setPolicyNo,
            }}
          />
          <InputFormElement
            labelProps={{
              labelTrKey: "registerForm.pidPlaceholder",
              tooltipTrKey: "registerForm.pidTooltipText",
            }}
            inputProps={{
              value: pid,
              onChange: setPid,
              inputType: "password",
            }}
          />
          <SubmitButton
            disabled={!isFormValid || loadingStatus === loadingStatuses.pending}
            type="submit"
            textTrKey="common.sendAuthorizationCode"
          />
        </FormWrapper>
      </ShadowedBox>
    </div>
  );
};
