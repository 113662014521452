import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { userActions } from "../../slices/userSlice";
import { Button, Icon, Title } from "../../components/UIComponents";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { ShadowedBox } from "../../components/UIComponents/styled";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";
import { LinkButton } from "../../components/UIComponents/LinkButton";

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, errorTrKey, formData } = useSelector(
    (state: DefaultRootState) => state.user
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const location = useLocation();
  const applyRemindLoginFlow = location.pathname === "/remind-login-final" && formData.username;

  useEffect(() => {
    if (applyRemindLoginFlow) setUsername(formData.username);
  }, [applyRemindLoginFlow, formData.username]);

  const credentialsCorrected = username !== formData?.username || password !== formData?.password;
  const isFormValid = !!(username && password) && credentialsCorrected;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid && loadingStatus !== loadingStatuses.pending) {
      dispatch(userActions.login({ username, password }));
    }
  };

  const navigate = useNavigate();
  return (
    <div>
      <ShadowedBox width={440}>
        <FormWrapper onSubmit={onSubmit}>
          {applyRemindLoginFlow && (
            <>
              {t("confirmUsername.yourUsernameIs")}
              {formData.username}. {t("usernameForm.enterPasswordToUsername")}
            </>
          )}
          <TitleWrapper>
            <Title>{t("usernameForm.title")}</Title>
          </TitleWrapper>

          {loadingStatus === loadingStatuses.failed &&
            !credentialsCorrected &&
            errorTrKey &&
            !!username &&
            !!password && (
              <ValidationWrapper>
                <ErrorWrapper>
                  <ErrorMessage>{t(errorTrKey)}</ErrorMessage>
                  <Icon name="alert-triangle" color="error" />
                </ErrorWrapper>
              </ValidationWrapper>
            )}

          <InputWrapper>
            <InputFormElement
              labelProps={{
                labelTrKey: "common.login",
                tooltipTrKey: "common.usernameTooltipText",
              }}
              inputProps={{
                value: username,
                onChange: !applyRemindLoginFlow ? setUsername : undefined,
                placeholder: "common.placeholders.username",
                isInvalid:
                  !!username && loadingStatus === loadingStatuses.failed && !credentialsCorrected,
              }}
            />
            <InputFormElement
              labelProps={{
                labelTrKey: "common.password",
              }}
              inputProps={{
                value: password,
                onChange: setPassword,
                placeholder: "usernameForm.passwordPlaceholder",
                inputType: "password",
                isInvalid:
                  !!password && loadingStatus === loadingStatuses.failed && !credentialsCorrected,
              }}
            />
            <LinksWrapper>
              <LinkButton onClick={() => navigate("/reset-password-init")} size={14}>
                {t("usernameForm.links.forgotPassword")}
              </LinkButton>
              {!applyRemindLoginFlow && (
                <LinkButton onClick={() => navigate("/remind-login-init")} size={14}>
                  {t("usernameForm.links.forgotUsername")}
                </LinkButton>
              )}
            </LinksWrapper>
          </InputWrapper>
          <SubmitButton
            disabled={loadingStatus === loadingStatuses.pending}
            type="submit"
            textTrKey="usernameForm.submit"
          />
          <Button
            buttonType="secondary"
            textTrKey="usernameForm.links.register"
            onClick={() => navigate("/register")}
            rightIconName={"user-plus"}
          />
        </FormWrapper>
      </ShadowedBox>
    </div>
  );
};

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ValidationWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.inset1};
  border-radius: 4px;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.errorBg2};
  border-radius: 4px;
  padding: 6px 12px;
  align-items: center;
  align-content: center;
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
  line-height: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubmitButton = styled(Button)`
  padding: 16px 32px;
`;
