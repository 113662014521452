import React from "react";
import styled from "styled-components/macro";

interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => (
  <Switch className={props.className}>
    <Input
      type="checkbox"
      disabled={props.disabled}
      checked={props.checked}
      onChange={(e) => props.onChange?.(e.target.checked)}
    />
    <Slider />
  </Switch>
);

const Switch = styled.label`
  position: relative;
  width: 44px;
  height: 24px;
  display: block;
`;

const Slider = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
  border-radius: 11.5px;
  background-color: ${({ theme }) => theme.colors.primary100};

  &:before {
    content: "";
    background-color: #fff;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 2px;
    left: 2px;
    transition: 0.2s;
    border-radius: 50%;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${Slider} {
    &:before {
      transform: translateX(20px);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgc3Ryb2tlPSIjMkYyRjJGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS13aWR0aD0iMS42Ij4KICAgICAgICAgICAgPHBhdGggZD0iTSAxNS4zMyA2IEwgOCAxMy4zMyBMIDQuNjYgMTAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=);
    }
  }
`;
