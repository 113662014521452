import React, { FC } from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { LinkButton } from "../UIComponents/LinkButton";

interface SendSmsAgainButtonProps {
  onClick: () => void;
  className?: string;
}

const SendSmsAgainButton: FC<SendSmsAgainButtonProps> = ({ onClick, className }) => (
  <Wrapper className={className}>
    <LinkButton onClick={onClick} size={14}>
      {t("common.sendAuthorizationCodeAgain")}
    </LinkButton>
  </Wrapper>
);

const Wrapper = styled.div`
  text-align: center;
`;

export default SendSmsAgainButton;
